import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import '../../embedded-video.css';

const HlsPlayer = ({ streamUrl }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [error, setError] = useState('');
  const [sessionKey, setSessionKey] = useState('');


  const getRanKey = size => {
    let result = [];
    let LETRef = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9',  
                  'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r','s','t','u','v','w','x','y','z',
                  'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R','S','T','U','V','W','X','Y','Z'
    ];
    for (let n = 0; n < size; n++) {
      result.push(LETRef[Math.floor(Math.random() * LETRef.length)]);
    }
    return result.join('');
  };

  useEffect(() => {
    setSessionKey(getRanKey(32));
  }, []);

  useEffect(() => {
    const checkStreamStatus = async () => {
      try {
        const response = await fetch(streamUrl, {
          method: 'HEAD' 
        });
        if (response.status === 410) {
          setError('The event is no longer available.');
          return;
        }
        if (response.status === 204) {
          setError('Content will start shortly.');
          return;
        }
        if (!response.ok) {
          setError('Failed to load the video.');
          return;
        }
        setupPlayer();
      } catch (error) {
        setError('Error loading video.');
      }
    };

    checkStreamStatus();

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
      }
    };
  }, [streamUrl]);

  const setupPlayer = () => {
    if (videoRef.current) {
      const modifiedStreamUrl = `${streamUrl}?viewSession=${getRanKey(32)}`;
      playerRef.current = videojs(videoRef.current, {
        autoplay: true,
        muted: true,
        preload: 'auto',
        sources: [{
          src: modifiedStreamUrl,
          type: 'application/x-mpegURL'
        }]
      });

      playerRef.current.ready(() => {
        playerRef.current.controls(true);
      });
    }
  };

  return (
    <div className="video-container">
  {error ? (
    <div className='flex items-center' style={{ width: '100%', height: '100%' }}>
      <div className='w-full text-center text-[18px]'>{error}</div>
    </div>
  ) : (
    <video
      id="decimal_video" 
      className="video-js" 
      controls 
      preload="none"
      ref={videoRef}
    ></video>
  )}
</div>
  );
};

export default HlsPlayer;
