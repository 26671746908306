import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layout/DefaultLayout";
import { useUser } from "../../UserContext";
import Cookies from 'js-cookie';
import { IoCaretDown, IoCaretForward  } from "react-icons/io5";

export const MatchList = () => {
    const { user } = useUser();
    const st = Cookies.get('ds_session_token');
    const [session_token, setSession_token] = useState(st);
    const [matches, setMatches] = useState([]);
    const [expandedMatches, setExpandedMatches] = useState([]);
    const sports = ["", "KABADDI", "PADEL", "", "CRICKET"];

    const generateDistinctColors = (numColors) => {
        const colors = [];
        const step = 360 / numColors;
        for (let i = 0; i < numColors; i++) {
            const hue = i * step;
            const color = `hsl(${hue}, 70%, 50%)`;
            colors.push(color);
        }
        return colors;
    };

    const keyColors = [
        "#FF5733", "#33FF57", "#3357FF", "#F3FF33", "#33FFF3",
        "#FF33A8", "#A833FF", "#33FFCE", "#FFB533", "#5733FF",
        "#FF338A", "#33FFAB", "#338AFF", "#FF8333",
        "#33FF5A", "#8333FF", "#FF335C", "#33FF8A", 
        "#33F3FF", "#FF33DA", "#5733FF", "#FF3333", "#33FF66",
        "#A833FF", "#33FFCE", "#FFB533",
        "#5733FF", "#FF338A", "#33FFAB", "#338AFF", "#FFF033",
        "#FF8333", "#33FF5A", "#8333FF", "#FF335C", "#33FF8A",
        "#33F3FF", "#FF33DA", "#5733FF", "#FF3333",
        "#33FF66", "#A833FF", "#33FFCE", 
    ];

    const formatDateTime = (dateTime) => {
        const date = new Date(dateTime);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    async function getMatches() {
        let baseUrl = process.env.REACT_APP_API_URL;
        let sessionToken = encodeURIComponent(session_token);
        let url = `${baseUrl}/mappedmatches/${sessionToken}`;
        const res = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_API_KEY
            },
        });
        const data = await res.json();
        setMatches(data.data);
    }

    useEffect(() => {
        getMatches();
    }, []);

    const handleExpandMatch = (matchId) => {
        setExpandedMatches(prevState => 
            prevState.includes(matchId) ? prevState.filter(id => id !== matchId) : [...prevState, matchId]
        );
    };

    return (
        <DefaultLayout>
            <h2 className="text-title-md2 font-semibold text-black dark:text-white mb-[20px]">Upcoming Matches</h2>

            <div className='w-full bg-[#01949a] text-white flex font-semibold'>
                <div className='w-[30px]'></div>
                <div className="w-[90px] border border-1 border-solid border-[#01949a] hidden lg:block text-left h-[60px] pt-[17px]"> SPORT </div>
                <div className="w-[200px] border border-1 border-solid border-[#01949a] hidden lg:block text-left h-[60px] pt-[17px]"> DATE (utc)</div>
                <div className="flex-grow border border-1 border-solid border-[#01949a] hidden lg:block text-left h-[60px] pt-[17px]"> NAME </div>
                <div className="w-[250px] border border-1 border-solid border-[#01949a] hidden lg:block text-left h-[60px] pt-[17px]"> COMPETITION </div>
            </div>

            {matches.map((match, idx) => (
                <div key={idx}>
                    <div className={`w-full flex bg-white py-[5px] border-b border-1 border-solid border-[#01949a] ${match.match_end!==null ? ('line-through') : (null)}`}>
                        <div className='w-[30px]'>
                            <div className='relative group'>
                                {expandedMatches.includes(match.match_id) ? (
                                    <IoCaretDown  
                                        className='text-[24px] cursor-pointer' 
                                        onClick={() => handleExpandMatch(match.match_id)} 
                                    />
                                ) : (
                                    <IoCaretForward
                                        className='text-[24px] cursor-pointer' 
                                        onClick={() => handleExpandMatch(match.match_id)} 
                                    />
                                )}
                                <div className="absolute top-1/2 left-full z-20 mr-3 -translate-y-1/2 whitespace-nowrap rounded bg-black px-4.5 py-1.5 text-sm font-medium text-white opacity-0 group-hover:opacity-100">
                                    <span className="absolute top-1/2 left-[-3px] -z-10 h-2 w-2 -translate-y-1/2 rotate-45 rounded-sm bg-black"></span>
                                    Expand Details
                                </div>
                            </div>
                        </div>
                        <div className='w-[90px]'>{sports[match.sport]}</div>
                        <div className='w-[200px]'>{formatDateTime(match.match_start)}</div>
                        <div className='flex-grow truncate'>{match.name}</div>
                        <div className='w-[250px] truncate'>{match.competition}</div>
                    </div>
                    {expandedMatches.includes(match.match_id) && (
                        <div className='bg-gray-100 p-4 border border-[#01949a] border-t-0 pl-[50px]'>
                            <div className='flex'>
                                <div className='w-[150px] font-bold'>ID:</div>
                                <div className='flex-grow'>{match.match_id}</div>
                            </div>
                            <div className='flex'>
                                <div className='w-[150px] font-bold'>EMBED ID:</div>
                                <div className='flex-grow'>{match.external_id}</div>
                            </div>
                            {match.match_end!==null ? (
                                <div className='flex'>
                                    <div className='w-[150px] font-bold'>ENDED:</div>
                                    <div className='flex-grow'>{formatDateTime(match.match_end)}</div>
                                </div>
                            ) : ( null )}
                            <div className='flex'>
                                <div className='w-[150px] font-bold'>EMBED CODE:</div>
                                <div className='flex-grow text-wrap bg-stroke h-[50px]'>
                                    <pre className='whitespace-pre-wrap'>
                                        <code className='whitespace-pre-wrap'>
                                        &lt;iframe width="560" height="315" src="{process.env.REACT_APP_EMBEDDED_PLAYER_URL}?id={match.external_id}" frameborder="0" allowfullscreen&gt;&lt;/iframe&gt;  
                                        </code>
                                    </pre>
                                </div>
                            </div>

                        </div>
                    )}
                </div>
            ))}
        </DefaultLayout>
    );
};
