import React, { useState, useEffect, useRef } from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import Cookies from 'js-cookie';

interface matchDetail {
  id: string;
  user_id: string;
  tournament_id: string;
  match_id: string;
  external_id: string;
  name: string;
  status: string;
  match_start: string;
  match_end: string;
  competition: string;
  tier: string;
}
interface ModalCalendarExpandProps {
  match_id: string;
  onUpdate: (id: string, newName: string, newId: string, newStartDate: string, newEndDate: string, selectedSport: string ) => void;
}

const ModalCalendarExpand: React.FC<ModalCalendarExpandProps> = ({ match_id, onUpdate }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [newName, setNewName] = useState('');
  const st = Cookies.get('ds_session_token');
  const [session_token, setSession_token] = useState(st);
  const [matchDetail, setMatchDetail] = useState<matchDetail | null>(null);

  const trigger = useRef<HTMLDivElement | null>(null);
  const modal = useRef<HTMLDivElement | null>(null);

  async function fetchMatch(session_token: string | undefined, match_id: string) {
    const apiUrl = process.env.REACT_APP_API_URL as string;
    const apiKey = process.env.REACT_APP_API_KEY as string;

    if (!apiUrl || !apiKey) {
      console.error('API URL or API Key is not defined');
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/mappingbymatchid/${session_token}/${match_id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        setMatchDetail(data.data);
        setModalOpen(true);

      } else {
        console.error('Error fetching competitions:', response.status);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!modal.current) return;
      if (
        !modalOpen ||
        modal.current.contains(target as Node) ||
        trigger.current?.contains(target as Node)
      ) return;
      setModalOpen(false);
    };

    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  }, [modalOpen]);

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!modalOpen || keyCode !== 27) return;
      setModalOpen(false);
    };

    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, [modalOpen]);

  const handleUpdate = () => {
    if (newName !== '') {
      //onUpdate(selectedParent, newName, newId, newStartDate, newEndDate, selectedSport);
      //(false);
    }
  };
  
  useEffect(() => {
    fetchMatch(session_token, match_id);
  }, [session_token, match_id]);

  return (
    <div>
      {matchDetail! ? (
      <div
        className={`fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/90 px-4 py-5 ${modalOpen ? 'block' : 'hidden'}`}
      >
        <div
          ref={modal}
          className="md:px-17.5 w-full max-w-142.5 rounded-lg bg-white px-8 py-12 text-center dark:bg-boxdark md:py-15"
        >
          <h3 className="pb-2 text-xl font-bold text-black dark:text-white sm:text-2xl">
            {matchDetail.name}
          </h3>
          <span className="mx-auto mb-6 inline-block h-1 w-22.5 rounded bg-[#01949a]"></span>
            <div className='flex'>
              <div className='w-[150px] text-right font-bold'>ID:</div>
              <div className='flex-grow text-left pl-[10px]'>{matchDetail.id}</div>
            </div>
            <div className='flex'>
              <div className='w-[150px] text-right font-bold'>DATE:</div>
              <div className='flex-grow text-left pl-[10px]'>{matchDetail.match_start}</div>
            </div>
            <div className='flex'>
              <div className='w-[150px] text-right font-bold'>COMPETITION:</div>
              <div className='flex-grow text-left pl-[10px]'>{matchDetail.competition}</div>
            </div>
            <div className='flex'>
              <div className='w-[150px] text-right font-bold'>EMBED ID:</div>
              <div className='flex-grow text-left pl-[10px]'>{matchDetail.external_id}</div>
            </div>
            <div className='flex'>
              <div className='w-[150px] text-right font-bold'>EMBED CODE:</div>
              <div className='flex-grow text-left pl-[10px]'><pre className='whitespace-pre-wrap'>
                                        <code className='whitespace-pre-wrap'>
                                        &lt;iframe width="560" height="315" src="{process.env.REACT_APP_EMBEDDED_PLAYER_URL}?id={matchDetail.external_id}" frameborder="0" allowfullscreen&gt;&lt;/iframe&gt;  
                                        </code>
                                    </pre></div>
            </div>


          <div className="mt-[30px] flex flex-wrap gap-y-4">
            <div className="w-full px-3">
              <button
                onClick={() => setModalOpen(false)}
                className="block w-full rounded border border-stroke bg-gray p-3 text-center font-medium text-black transition hover:border-meta-1 hover:bg-meta-1 hover:text-white dark:border-strokedark dark:bg-meta-4 dark:text-white dark:hover:border-meta-1 dark:hover:bg-meta-1"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      ) : ( null )}
    </div>
  );
};

export default ModalCalendarExpand;
