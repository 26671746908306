import React, { useState, ChangeEvent, FormEvent } from "react";
import DefaultLayout from "../../layout/DefaultLayout";

import { Link } from 'react-router-dom';
import axios from "axios";

 

export const Dashboard = () => {

    return (
        <DefaultLayout>
            <div>HELLO</div>

        </DefaultLayout>
    )

};