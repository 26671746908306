import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import Cookies from 'js-cookie';

interface User {
  id: string;
  teamid: string;
  role: string;
  email: string;
  name: string;
}

interface UserContextProps {
  user: User | null;
  setUser: (user: User | null) => void;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const session_token = Cookies.get('ds_session_token');
  
  useEffect(() => {
    if (!session_token) {
      setUser(null);
      return;
    }

    const checkSession = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/checksession/${session_token}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY || '',
          },
        });
        if (response.ok) {
          const userData = await response.json();
          setUser(userData.data[0]);
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Failed to check session', error);
        setUser(null);
      }
    };

    checkSession();
  }, [session_token]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
