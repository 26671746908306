import React, { useEffect, useState, useRef } from "react";
import DefaultLayout from "../../layout/DefaultLayout";
import { Link } from 'react-router-dom';
import { useUser } from "../../UserContext";
import Cookies from 'js-cookie';

export const BookingsUpcoming = () => {
    const { user } = useUser();
    const st = Cookies.get('ds_session_token');
    const [session_token, setSession_token] = useState(st);
    const [loading, setLoading] = useState(true);
    const [tournaments, setTournaments] = useState([]);
    const [fullTournamentList, setFullTournamentList] = useState([]);
    const [sports, setSports] = useState([]);
    const [bookings, setBookings] = useState([]);
    
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    };

    async function getJWT() {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/jwttoken/lBdde73qPBBxVI911719227087743`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_API_KEY,
                },
            });
            if (response.status === 200) {
                //console.log(response.data);
            } else {
                throw new Error('Failed to fetch data. Status: ' + response.status);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    async function getUserSelection(session_token) {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/bookedTournamnets/${session_token}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_API_KEY,
                },
            });
            if (response.status === 200) {
                const data = await response.json();
                setBookings(data.data);
            } else {
                throw new Error('Failed to fetch data. Status: ' + response.status);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    async function fetchUpcoming(session_token) {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/tournaments`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_API_KEY,
                },
            });
            if (response.status === 200) {
                const data = await response.json();
                let bd = [];
                let ft = [];
                let sp = [];
                for (let i = 0; i < data.data.length; i++) {
                    if (data.data[i].date_from !== null && data.data[i].date_to !== null) {
                        bd.push(data.data[i]);
                    }
                    ft[data.data[i].id] = data.data[i];

                    if (!sp.find(item => item.name === data.data[i].sport)) {
                        sp.push({ name: data.data[i].sport, selected: true });
                    }
                }
                const sortedItems = [...bd].sort((a, b) => new Date(a.date_from) - new Date(b.date_from));
                setTournaments(sortedItems);
                setFullTournamentList(ft);
                setSports(sp);
                getUserSelection(session_token);
                setLoading(false);
            } else {
                throw new Error('Failed to fetch data. Status: ' + response.status);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    async function saveSelection(session_token, tournamentId, selection) {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/saveselections/${session_token}`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_API_KEY,
                },
                body: JSON.stringify({ tournamentId: tournamentId, selection: selection })
            });
            if (response.status === 200) {
                const data = await response.json();
                setBookings(data.data);
            } else {
                throw new Error('Failed to fetch data. Status: ' + response.status);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {

        //getJWT();


        fetchUpcoming(session_token);
    }, [session_token]);

    const toggleSport = (sportIndex) => {
        setSports(prevSports =>
            prevSports.map((sport, index) =>
                index === sportIndex ? { ...sport, selected: !sport.selected } : sport
            )
        );
    };

    const toggleSelection = (tournamentIndex,isChecked) => {
        saveSelection(session_token, tournamentIndex, isChecked);
    };

    const filteredTournaments = tournaments.filter(tour =>
        sports.find(sport => sport.name === tour.sport && sport.selected)
    );

    const filteredFullTournamentList = fullTournamentList.filter(ftour =>
        sports.find(sport => sport.name === ftour.sport && sport.selected)
    );

    return (
        <DefaultLayout>
            <h2 className="text-title-md2 font-semibold text-black dark:text-white mb-[20px]">Upcoming Bookings</h2>
            <div className="mt-[20px] rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark flex">
                    <h3 className="font-medium text-black dark:text-white flex-grow">Upcoming Streams</h3>
                    <div className='flex capitalize'>
                        {sports.map((sport, i) => (
                            <div
                                key={i}
                                className={`cursor-pointer border border-1 border-solid border-stroke px-[8px] ml-[5px] ${sport.selected === true ? 'bg-[#01949a] text-white' : ''}`}
                                onClick={() => toggleSport(i)}
                            >
                                {sport.name}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {loading ? (
                <img src='/Spinner.gif' width='40px' height='40px' />
            ) : (
                <div>
                    <div className='mt-[20px] rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark p-[20px]'>

                        <div className='flex font-bold'>
                            <div className='w-[25px]'></div>
                            <div className='flex-grow'>Tournament</div>
                            <div className='w-[300px]'>Series</div>
                            <div className='w-[150px]'>Sport</div>
                            <div className='w-[300px]'>Dates</div>
                        </div>

                        {filteredTournaments.map((tour, idx) => (
                            <div className='flex' key={idx}>
                                <div className='w-[25px]'>
                                    <input
                                        key={tour.id}
                                        type='checkbox'
                                        checked={bookings.indexOf(tour.id) > -1 ? (true) : (false)}
                                        onChange={(e) => toggleSelection(tour.id, e.target.checked)}
                                    />
                                </div>
                                <div className='flex-grow'>
                                    {tour.tournament}
                                </div>
                                <div className='w-[300px]'>
                                    {tour.parent !== null ? (
                                        <>
                                            {fullTournamentList[tour.parent].tournament}
                                        </>
                                    ) : (null)}
                                </div>
                                <div className='w-[150px] capitalize'>
                                    {tour.sport}
                                </div>
                                <div className='w-[300px]'>
                                    {formatDate(tour.date_from)} - {formatDate(tour.date_to)}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='mt-[20px] rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark p-[20px]'>

                        <div className='flex font-bold'>
                            <div className='w-[25px]'></div>
                            <div className='flex-grow'>Series</div>
                            <div className='w-[200px]'>Sport</div>
                        </div>

                        {filteredFullTournamentList.map((ftour, idx) => (
                            <React.Fragment key={idx}>
                                {ftour.parent === null ? (
                                    <>
                                        <div className='flex'>
                                            <div className='w-[25px]'>
                                                <input
                                                    key={ftour.id}
                                                    type='checkbox'
                                                    checked={bookings.indexOf(ftour.id) > -1 ? (true) : (false)}
                                                    onChange={(e) => toggleSelection(ftour.id, e.target.checked)}
                                                />
                                            </div>
                                            <div className='flex-grow'>
                                                {ftour.tournament}
                                            </div>
                                            <div className='w-[200px] capitalize'>
                                                {ftour.sport}
                                            </div>
                                        </div>
                                        {filteredFullTournamentList.map((t2, i2) => (
                                            <React.Fragment key={i2}>
                                                {t2.parent === ftour.id ? (
                                                    <>
                                                    <div className='flex'>
                                                        <div className='w-[50px] pl-[25px]'>
                                                            <input
                                                                key={t2.id}
                                                                type='checkbox'
                                                                checked={bookings.indexOf(t2.id) > -1 ? (true) : (false)}
                                                                onChange={(e) => toggleSelection(t2.id, e.target.checked)}
                                                            />
                                                        </div>
                                                        <div className='flex-grow'>
                                                            {t2.tournament}
                                                        </div>
                                                        <div className='w-[200px] capitalize'>
                                                            {t2.sport}
                                                        </div>
                                                    </div>
                                                    {filteredFullTournamentList.map((t3,i3) => (
                                                        <>
                                                        {t3.parent===t2.id ? (
                                                        <div className='flex pl-[50px]'>
                                                            <div className='pt-[3px] pr-[5px]'>
                                                            <input
                                                                key={t3.id}
                                                                type='checkbox'
                                                                checked={bookings.indexOf(t3.id) > -1 ? (true) : (false)}
                                                                onChange={(e) => toggleSelection(t3.id, e.target.checked)}
                                                            />
                                                            </div>
                                                            <div className='flex-grow'>
                                                                {t3.tournament}
                                                            </div>
                                                            <div className='w-[200px] capitalize'>
                                                                {t3.sport}
                                                            </div>
                                                        </div>
                                                        ) : ( null )}
                                                        </>
                                                    ))}
                                                    </>
                                                ) : (null)}
                                            </React.Fragment>
                                        ))}
                                    </>
                                ) : (null)}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            )}
        </DefaultLayout>
    )
};
