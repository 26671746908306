import React, { useEffect, useState, useRef } from "react";
import DefaultLayout from "../../layout/DefaultLayout";
import { Link } from 'react-router-dom';
import { useUser } from "../../UserContext";
import Cookies from 'js-cookie';

export const Player = () => {
    const { user } = useUser();
    const st = Cookies.get('ds_session_token');
    const [session_token, setSession_token] = useState(st);
    const [loading, setLoading] = useState(true);
    const [integrationType, setIntegrationType] = useState('per_match');
    const [updating, setUpdating] = useState(false);
    
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    };

    async function getJWT() {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/jwttoken/lBdde73qPBBxVI911719227087743`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_API_KEY,
                },
            });
            if (response.status === 200) {
                const data = await response.json();
                console.log(data);
                setLoading(false);
            } else {
                throw new Error('Failed to fetch data. Status: ' + response.status);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    async function fetchSettings(session_token) {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/playersettings/${session_token}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_API_KEY,
                },
            });
            if (response.status === 200) {
                const data = await response.json();
                setIntegrationType(data.data.player_integration_type);
                console.log(data.data);
                


                setLoading(false);
            } else {
                throw new Error('Failed to fetch data. Status: ' + response.status);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    async function saveSettings(session_token) {
        setUpdating(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/savesettings/${session_token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_API_KEY,
                },
                body: JSON.stringify({ integrationType: integrationType })
            });
            setUpdating(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    useEffect(() => {

        //getJWT();
        fetchSettings(session_token);
    }, [session_token]);

    const handleRadioChange = (event) => {
        setIntegrationType(event.target.value);
    };
    const handleSaveSettings = (event) => {
        saveSettings(session_token);
    };

    return (
        <DefaultLayout>
            <h2 className="text-title-md2 font-semibold text-black dark:text-white mb-[20px]">Player</h2>
            <div className="mt-[20px] rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark flex">
                    <h3 className="font-medium text-black dark:text-white flex-grow">Player Integration Type</h3>
                </div>
            </div>
            {loading ? (
                <img src='/Spinner.gif' width='40px' height='40px' />
            ) : (
                <div>
                    <div className='flex'>
                        <div className={`w-[50%] rounded-sm border border-stroke ${integrationType==='per_match' ? ('bg-white') : ('bg-gray')} shadow-default dark:border-strokedark dark:bg-boxdark p-[30px] pb-[40px]`}>
                            <div className='text-center font-bold'>
                                Unique Id per Match <span className='text-[#db1f48]'>*Recommended*</span>
                            </div>
                            <p className='pt-[20px] text-center'>
                            <input
                                type="radio"
                                name="intType"
                                value="per_match"
                                checked={integrationType === 'per_match'}
                                onChange={handleRadioChange}
                            />
                            </p>
                            <p className='pt-[20px] text-center'>
                                A unique embedded endpoint is provided via API per match. Requires integration with Decimal media player api.
                            </p>
                        </div>
                        <div className={`w-[50%] rounded-sm border border-stroke ${integrationType==='per_tournament' ? ('bg-white') : ('bg-gray')} shadow-default dark:border-strokedark dark:bg-boxdark p-[30px] pb-[40px]`}>
                            <div className='text-center font-bold'>
                                Unique Id per Tournament
                            </div>
                            <p className='pt-[20px] text-center'>
                                <input
                                    type="radio"
                                    name="intType"
                                    value="per_tournament"
                                    checked={integrationType === 'per_tournament'}
                                    onChange={handleRadioChange}
                                />
                            </p>
                            <p className='pt-[20px] text-center'>
                                A unique embedded endpoint is provided via API per tournament. A quicker integration route, but less secure.
                            </p>
                        </div>
                    </div>
                    <div className='mt-[40px] text-left'>
                        {updating ? (
                            <img src='/Spinner.gif' width='40px' height='40px' />
                        ) : (
                            <div className="bg-[#019399] py-4 px-10 font-medium text-white hover:bg-opacity-90 inline cursor-pointer" onClick={handleSaveSettings} >
                                Update Settings
                            </div>
                        )}
                    </div>
                </div>
            )}
        </DefaultLayout>
    )
};
