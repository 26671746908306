import React from 'react';
import HlsPlayer from '../HlsPlayer';
import { useLocation } from 'react-router-dom';


const AntMediaPlayer = () => {
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');

  return (
    <div>
      <HlsPlayer streamUrl={`${process.env.REACT_APP_EMBEDDED_PROXY}/${id}`} />
    </div>
    
  );
};

export default AntMediaPlayer;
