import React, { useState, useEffect, useRef } from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

interface Parent {
  id: string;
  tournament: string;
  parent: string;
}

interface Competition {
  id: string;
  name: string;
  shortname: string;
  host: string;
  host_id: string;
  category: string;
  format: string;
  gender: string;
  languages: string | null;
}

interface ModalAddNewTournamentProps {
  parents: Parent[];
  onUpdate: (id: string, newName: string, newId: string, newStartDate: string, newEndDate: string, selectedSport: string ) => void;
}

const ModalAddNewTournament: React.FC<ModalAddNewTournamentProps> = ({ parents, onUpdate }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [newName, setNewName] = useState('');
  const [newId, setNewId] = useState('');
  const [newStartDate, setNewStartDate] = useState('');
  const [newEndDate, setNewEndDate] = useState('');
  const [selectedParent, setSelectedParent] = useState('');
  const [selectedCompetitions, setSelectedCompetitions] = useState<Competition[]>([]);
  const [selectedSport, setSelectedSport]= useState('cricket');
  const [competitions, setCompetitions] = useState<Competition[]>([]);

  const trigger = useRef<HTMLDivElement | null>(null);
  const modal = useRef<HTMLDivElement | null>(null);

  async function fetchCompetitions() {
    const apiUrl = process.env.REACT_APP_API_URL as string;
    const apiKey = process.env.REACT_APP_API_KEY as string;

    if (!apiUrl || !apiKey) {
      console.error('API URL or API Key is not defined');
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/competitions`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        setCompetitions(data.data.competitions);
      } else {
        console.error('Error fetching competitions:', response.status);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!modal.current) return;
      if (
        !modalOpen ||
        modal.current.contains(target as Node) ||
        trigger.current?.contains(target as Node)
      ) return;
      setModalOpen(false);
    };

    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  }, [modalOpen]);

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!modalOpen || keyCode !== 27) return;
      setModalOpen(false);
    };

    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, [modalOpen]);

  const handleUpdate = () => {
    if (newName !== '') {
      onUpdate(selectedParent, newName, newId, newStartDate, newEndDate, selectedSport);
      setModalOpen(false);
    }
  };

  const handleOnSearch = (string: string, results: Competition[]) => {
    // console.log(string, results);
  }

  const handleOnHover = (result: Competition) => {
    // console.log(result);
  }

  const handleOnSelect = (item: Competition) => {
    setNewName(item.name);
    setNewId(item.id);
  }

  const handleOnFocus = () => {
    // console.log('Focused');
  }

  useEffect(() => {
    fetchCompetitions();
  }, []);

  return (
    <div>
      <div ref={trigger} onClick={() => setModalOpen(!modalOpen)}>
        <div className="bg-[#019399] py-4 px-10 font-medium text-white hover:bg-opacity-90 cursor-pointer inline-block">
          Add New Tournament
        </div>
      </div>

      <div
        className={`fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/90 px-4 py-5 ${modalOpen ? 'block' : 'hidden'}`}
      >
        <div
          ref={modal}
          className="md:px-17.5 w-full max-w-142.5 rounded-lg bg-white px-8 py-12 text-center dark:bg-boxdark md:py-15"
        >
          <h3 className="pb-2 text-xl font-bold text-black dark:text-white sm:text-2xl">
            New Tournament
          </h3>
          <span className="mx-auto mb-6 inline-block h-1 w-22.5 rounded bg-[#01949a]"></span>
          <div className="mt-[10px] flex">
            <div className='flex-grow'>
              <ReactSearchAutocomplete
                className=''
                items={competitions}
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={handleOnSelect}
                onFocus={handleOnFocus}
                autoFocus
                placeholder='Search for tournament / competition'
              />
            </div>
          </div>


          <div className='pt-[20px] pb-[10px]'>
            <hr />
          </div>


          <div className="mt-[10px] flex">
            <div className='w-[120px] pt-[5px] pr-[10px] text-right'>ID:</div>
            <div className='flex-grow'>
              <input
                type='text'
                value={newId}
                onChange={(e) => setNewId(e.target.value)}
                className='w-full border border-1 border-solid border-[#01949a] p-[5px]'
              />
            </div>
          </div>

          <div className="mt-[10px] flex">
            <div className='w-[120px] pt-[5px] pr-[10px] text-right'>NAME:</div>
            <div className='flex-grow'>
              <input
                type='text'
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                className='w-full border border-1 border-solid border-[#01949a] p-[5px]'
              />
            </div>
          </div>

          <div className="mt-[10px] flex">
            <div className='w-[120px] pt-[5px] pr-[10px] text-right'>START DATE:</div>
            <div className='flex-grow'>
              <input
                type='text'
                value={newStartDate}
                onChange={(e) => setNewStartDate(e.target.value)}
                className='w-full border border-1 border-solid border-[#01949a] p-[5px]'
                placeholder='dd/mm/yyyy'
              />
            </div>
          </div>

          <div className="mt-[10px] flex">
            <div className='w-[120px] pt-[5px] pr-[10px] text-right'>END DATE:</div>
            <div className='flex-grow'>
              <input
                type='text'
                value={newEndDate}
                onChange={(e) => setNewEndDate(e.target.value)}
                className='w-full border border-1 border-solid border-[#01949a] p-[5px]'
                placeholder='dd/mm/yyyy'
              />
            </div>
          </div>


          <div className="mt-[10px] flex">
            <div className='w-[120px] pt-[5px] pr-[10px] text-right'>PARENT:</div>
            <div className='flex-grow'>
              <select
                className='w-full border border-1 border-solid border-[#01949a] p-[5px]'
                value={selectedParent}
                onChange={(e) => setSelectedParent(e.target.value)}
              >
                <option value=''>None</option>
                {parents.map((p) => (
                  <option key={p.id} value={p.id}>{p.tournament}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="mt-[10px] flex">
            <div className='w-[120px] pt-[5px] pr-[10px] text-right'>SPORT:</div>
            <div className='flex-grow'>
            <select
                className='w-full border border-1 border-solid border-[#01949a] p-[5px]'
                value={selectedSport}
                onChange={(e) => setSelectedSport(e.target.value)}
              >
                <option value='cricket'>Cricket</option>
                <option value='padel'>Padel</option>
                <option value='kabaddi'>Kabaddi</option>
              </select>
              
            </div>
          </div>

          <div className="mt-[30px] flex flex-wrap gap-y-4">
            <div className="2xsm:w-1/2 w-full px-3">
              <button
                onClick={() => setModalOpen(false)}
                className="block w-full rounded border border-stroke bg-gray p-3 text-center font-medium text-black transition hover:border-meta-1 hover:bg-meta-1 hover:text-white dark:border-strokedark dark:bg-meta-4 dark:text-white dark:hover:border-meta-1 dark:hover:bg-meta-1"
              >
                Cancel
              </button>
            </div>
            <div className="2xsm:w-1/2 w-full px-3">
              <button
                onClick={handleUpdate}
                className="block w-full rounded border border-[#01949a] bg-[#01949a] p-3 text-center font-medium text-white transition hover:bg-opacity-90"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAddNewTournament;
