import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { UserProvider } from './UserContext';

import { Home } from "./Home";
import { About } from "./About";
import { Account } from "./pages/Account";
import { AdminTournament } from "./pages/Admin/Tournaments";
import AntMediaPlayer from "./components/AntMediaPlayer";
import { BookingHistory } from "./pages/Bookings/History";
import { BookingsUpcoming } from "./pages/Bookings/Upcoming";
import { Calendar } from "./pages/Calendar";
import { Dashboard } from "./pages/Dashboard";
import { Login } from "./Login";
import { MatchList } from "./pages/MatchList";
import { Player } from "./pages/Player";
import Loader from "./common/Loader";
import PageTitle from './components/PageTitle';
import PrivateRoute from './PrivateRoute';
import './css/style.css';
import './css/satoshi.css';
import './css/simple-datatables.css';

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 300);
  }, []);

  return (
    <UserProvider>
      <Router>
        {loading ? (
          <Loader />
        ) : (
          <>
            <ScrollToTop />
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <PageTitle title="Decimal Sports" />
                    <Login />
                  </>
                }
              />
              <Route
                path="/embeddedplayer"
                element={
                  <>
                    <PageTitle title="Decimal Sports" />
                    <AntMediaPlayer />
                  </>
                }
              />
              <Route
                path="/login"
                element={
                  <>
                    <PageTitle title="Login | Decimal Sports" />
                    <Login />
                  </>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <>
                  <PageTitle title="Decimal Sports | Dashboard" />
                  <PrivateRoute component={Dashboard} />
                  </>
                }
              />
              <Route
                path="/account"
                element={
                  <>
                  <PageTitle title="Decimal Sports | Dashboard" />
                  <PrivateRoute component={Account} />
                  </>
                }
              />
              <Route
                path="/calendar"
                element={
                  <>
                  <PageTitle title="Decimal Sports | Calendar" />
                  <PrivateRoute component={Calendar} />
                  </>
                }
              />
              <Route
                path="/admin_tournament"
                element={
                  <>
                  <PageTitle title="Decimal Sports | Calendar" />
                  <PrivateRoute component={AdminTournament} />
                  </>
                }
              />
              <Route
                path="/match_list"
                element={
                  <>
                  <PageTitle title="Decimal Sports | Match List" />
                  <PrivateRoute component={MatchList} />
                  </>
                }
              />
              <Route
                path="/bookings_upcoming"
                element={
                  <>
                  <PageTitle title="Decimal Sports | Upcoming Bookings" />
                  <PrivateRoute component={BookingsUpcoming} />
                  </>
                }
              />
              <Route
                path="/match_history"
                element={
                  <>
                  <PageTitle title="Decimal Sports | Bookings History" />
                  <PrivateRoute component={BookingHistory} />
                  </>
                }
              />
              <Route
                path="/player"
                element={
                  <>
                  <PageTitle title="Decimal Sports | Media Player" />
                  <PrivateRoute component={Player} />
                  </>
                }
              />
            </Routes>
          </>
        )}
      </Router>
    </UserProvider>
  );
}

export default App;
