import React, { useEffect, useState, useRef } from "react";
import DefaultLayout from "../../layout/DefaultLayout";
import { Link } from 'react-router-dom';
import { useUser } from "../../UserContext";
import Cookies from 'js-cookie';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

export const BookingHistory = () => {
    const { user } = useUser();
    const st = Cookies.get('ds_session_token');
    const [session_token, setSession_token] = useState(st);
    const [loading, setLoading] = useState(true);
    const [thisMonth, setThisMonth] = useState();
    const [thisYear, setThisYear] = useState();
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1); 
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [history, setHistory] = useState([]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    };
    
    
    async function getMatches(session_token, m, y) {
        setLoading(true);
        let baseUrl = process.env.REACT_APP_API_URL;
        let sessionToken = encodeURIComponent(session_token);
        let url = `${baseUrl}/customerhistory/${sessionToken}/${m}/${y}`;
        const res = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_API_KEY
            },
        });
        const data = await res.json();
        setHistory(data.data);
        setLoading(false);
    }

    const handleDecreaseMonth = (event) => {
        let newMonth = currentMonth - 1;
        let newYear = currentYear;
    
        if (newMonth < 1) {
          newMonth = 12;
          newYear -= 1;
        }
    
        setCurrentMonth(newMonth);
        setCurrentYear(newYear);
        getMatches(session_token, newMonth, newYear);
    };

    const handleIncreaseMonth = (event) => {
        let newMonth = currentMonth + 1;
        let newYear = currentYear;
    
        if (newMonth > 12) {
          newMonth = 1;
          newYear += 1;
        }
    
        setCurrentMonth(newMonth);
        setCurrentYear(newYear); 
        getMatches(session_token, newMonth, newYear);
    };

    useEffect(() => {
        const date = new Date(currentYear, currentMonth - 1); 
        const monthName = date.toLocaleString('default', { month: 'long' });
        setThisMonth(monthName);
        setThisYear(currentYear);
    }, [currentMonth, currentYear]);

    useEffect(() => {
        getMatches(session_token, currentMonth, currentYear);
    }, [session_token]);

    return (
        <DefaultLayout>
            <h2 className="text-title-md2 font-semibold text-black dark:text-white mb-[20px]">Bookings History</h2>

            <div className='flex h-[40px] mb-[10px]'>
                <div className='border border-1 border-solid border-[#01949a] text-[18px] pt-[10px] px-[10px] hover:bg-[#01949a] hover:text-[#fff] hover:bg-opacity-90 cursor-pointer'
                    onClick={handleDecreaseMonth} >
                    <FaAngleLeft />
                </div>
                <div className='bg-[#01949a] w-[150px] text-center'>
                    <h3 className='font-bold pt-[9px] px-[10px] text-white'>{thisMonth} {thisYear}</h3>
                </div>
                <div className='border border-1 border-solid border-[#01949a] text-[18px] pt-[10px] px-[10px] hover:bg-[#01949a] hover:text-[#fff] hover:bg-opacity-90 cursor-pointer'
                    onClick={handleIncreaseMonth} >
                    <FaAngleRight />
                </div>
            </div>

            <div className="mt-[20px] rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark flex">
                    <h3 className="font-medium text-black dark:text-white flex-grow">History of Streams</h3>
                    <div className='flex capitalize'>
                    </div>
                </div>
            </div>
            {loading ? (
                <img src='/Spinner.gif' width='40px' height='40px' />
            ) : (
                <div>
                    <div className='mt-[20px] rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark p-[20px]'>
                        <div className='flex font-bold'>
                            <div className='w-[200px]'>ID</div>
                            <div className='flex-grow'>Name</div>
                            <div className='w-[250px]'>Tournament</div>
                            <div className='w-[200px]'>Date / Time (utc)</div>
                        </div>

                        {history.map((hist, idx) => (
                            <div className='flex'>
                                <div className='w-[200px]'>{hist.match_id}</div>
                                <div className='flex-grow'>{hist.name}</div>
                                <div className='w-[250px]'>{hist.competition}</div>
                                <div className='w-[200px]'>{formatDate(hist.match_start)} / {hist.match_start.substring(11,19)}</div>
                            </div>
                        ))}

                        <div className='mt-[20px] flex'>
                            <div className='font-bold'>TOTAL:</div>
                            <div className='pl-[10px]'>{history.length}</div>
                        </div>

                    </div>


                </div>
            )}
        </DefaultLayout>
    )
};
