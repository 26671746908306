import React, { useEffect, useState, useRef } from "react";
import DefaultLayout from "../../layout/DefaultLayout";
import { Link } from 'react-router-dom';
import axios from "axios";
import { useUser } from "../../UserContext";
import ModalConfirmDelete from "../../components/Modals/ModalConfirmDelete"; 
import ModalEditApiName from "../../components/Modals/ModalEditApiName";
import Cookies from 'js-cookie';
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaRegCheckCircle, FaRegEdit } from "react-icons/fa";
import { RxCrossCircled } from "react-icons/rx";

export const Account = () => {
    const { user } = useUser();
    const st = Cookies.get('ds_session_token');
    const [session_token, setSession_token] = useState(st);
    const [creatingKey, setCreatingKey] = useState(false);
    const [apiKeys, setApiKeys] = useState([]);
  
    async function fetchKeys(session_token) {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/apikeys/${session_token}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_API_KEY,
                },
            });
    
            if (response.status === 200) {
                const data = await response.json();
                setApiKeys(data.data);
            } else {
                throw new Error('Failed to fetch data. Status: ' + response.status);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    async function toggleKeys(tog, keyid, session_token) {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/togglekey/${session_token}/${tog}/${keyid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_API_KEY,
                },
            });
    
            if (response.ok) {
                fetchKeys(session_token);
            } else {
                console.error('Failed to toggle key. Status:', response.status);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    
    async function deleteKey(keyid, session_token) {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/deletekey/${session_token}/${keyid}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_API_KEY,
                },
            });
            if (response.ok) {
                fetchKeys(session_token);
            } else {
                console.error('Failed to delete key. Status:', response.status);
            }
        } catch (error) {
            console.error('Error deleting key:', error);
        }
    }

    async function updateKeyName(keyid, newName, session_token) {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/updatekeyname/${session_token}/${keyid}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_API_KEY,
                },
                body: JSON.stringify({ name: newName }),
            });
            if (response.ok) {
                fetchKeys(session_token);
            } else {
                console.error('Failed to update key name. Status:', response.status);
            }
        } catch (error) {
            console.error('Error updating key name:', error);
        }
    }

    useEffect(() => {
        fetchKeys(session_token);
    }, [session_token]);

    async function CreateKey() {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/createapikey/${session_token}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_API_KEY
            },
        });
        const data = await res.json();
        fetchKeys(session_token);
        setCreatingKey(false);
    }

    const handleCreateKey = (event) => {
        setCreatingKey(true);
        CreateKey(session_token);
    };

    const handleKeyToggle = (tog, keyid) => {
        toggleKeys(tog, keyid, session_token);
    };

    const handleKeyDelete = (keyid) => {
        deleteKey(keyid, session_token);
    };

    const handleKeyNameUpdate = (keyid, newName) => {
        updateKeyName(keyid, newName, session_token);
    };

    return (
        <DefaultLayout>
            <h2 className="text-title-md2 font-semibold text-black dark:text-white">Account</h2>
            <div className="mt-[20px] rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
                    <h3 className="font-medium text-black dark:text-white">Account Details</h3>
                </div>
                <div className="flex flex-col gap-5.5 p-6.5">
                    <div>
                        <label className="mb-3 block text-black dark:text-white">Your Name</label>
                        <input type="text" value={user.name} placeholder="Your name" className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary" />
                    </div>
                    <div>
                        <label className="mb-3 block font-medium text-black dark:text-white">Email</label>
                        <input type="text" value={user.email} placeholder="Your email" disabled="" className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black" />
                    </div>
                </div>
            </div>
            <div className="mt-[20px] rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
                    <h3 className="font-medium text-black dark:text-white">API Keys</h3>
                </div>
                <div className="p-6.5">
                    {creatingKey ? (
                        <div>
                            <AiOutlineLoading3Quarters className='loading' />
                        </div>
                    ) : (
                        <div className="bg-[#019399] py-4 px-10 font-medium text-white hover:bg-opacity-90 inline cursor-pointer" onClick={handleCreateKey} >
                            Create a New API Key
                        </div>
                    )}

                    <div className='py-[20px]'>
                        <div className="flex bg-stroke mt-[20px]">
                            <div className="p-0 xl:p-5 w-[20px]">
                                <h5 className="text-sm font-medium uppercase xsm:text-base"></h5>
                            </div>
                            <div className="p-2.5 text-left xl:p-5 w-[300px]">
                                <h5 className="text-sm font-medium uppercase xsm:text-base">Name</h5>
                            </div>
                            <div className="p-2.5 text-left xl:p-5 flex-grow">
                                <h5 className="text-sm font-medium uppercase xsm:text-base">Key</h5>
                            </div>
                            <div className="hidden p-2.5 text-left sm:block xl:p-5 w-[200px]">
                                <h5 className="text-sm font-medium uppercase xsm:text-base">Created</h5>
                            </div>
                            <div className="p-2.5 text-center sm:block xl:p-5 w-[75px]">
                                <h5 className="text-sm font-medium uppercase xsm:text-base"></h5>
                            </div>
                        </div>

                        {apiKeys.map((key, idx) => (
                            <div className="flex mt-[20px] border-b border-1 border-solid border-stroke" key={idx}>
                                <div className="p-0 xl:p-5 w-[20px]">
                                    <h5 className="text-sm font-medium uppercase xsm:text-base pt-[5px]">
                                        {key.disabled ? (
                                            <div className="group relative inline-block" onClick={() => handleKeyToggle('enable', key.id)}>
                                                <RxCrossCircled className='text-[#f04b50]' />
                                                <div className="absolute bottom-full left-1/2 z-20 mb-3 -translate-x-1/2 whitespace-nowrap rounded bg-black px-4.5 py-1.5 text-sm font-medium text-white opacity-0 group-hover:opacity-100">
                                                    <span className="absolute bottom-[-3px] left-1/2 -z-10 h-2 w-2 -translate-x-1/2 rotate-45 rounded-sm bg-black"></span>
                                                    Key Disabled, click to enable.
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="group relative inline-block" onClick={() => handleKeyToggle('disable', key.id)}>
                                                <FaRegCheckCircle className='text-[#01949a]' />
                                                <div className="absolute bottom-full left-1/2 z-20 mb-3 -translate-x-1/2 whitespace-nowrap rounded bg-black px-4.5 py-1.5 text-sm font-medium text-white opacity-0 group-hover:opacity-100">
                                                    <span className="absolute bottom-[-3px] left-1/2 -z-10 h-2 w-2 -translate-x-1/2 rotate-45 rounded-sm bg-black"></span>
                                                    Key Active, click to disable.
                                                </div>
                                            </div>
                                        )}
                                    </h5>
                                </div>
                                <div className="p-2.5 text-left xl:p-5 w-[300px]">
                                    <h5 className="text-sm font-medium xsm:text-base flex">
                                        <div className='flex-grow'>{key.name}</div>
                                        <div className='relative group pt-[4px]'>
                                            <ModalEditApiName apiKeyId={key.id} currentName={key.name} onUpdate={handleKeyNameUpdate} />
                                            <div className="absolute bottom-full left-1/2 z-20 mb-3 -translate-x-1/2 whitespace-nowrap rounded bg-black px-4.5 py-1.5 text-sm font-medium text-white opacity-0 group-hover:opacity-100">
                                                <span className="absolute bottom-[-3px] left-1/2 -z-10 h-2 w-2 -translate-x-1/2 rotate-45 rounded-sm bg-black"></span>
                                                Change API Key Name
                                            </div>
                                        </div>
                                    </h5>
                                </div>
                                <div className="p-2.5 text-left xl:p-5 flex-grow">
                                    <h5 className="text-sm font-medium xsm:text-base">{key.api_key}</h5>
                                </div>
                                <div className="hidden p-2.5 text-left sm:block xl:p-5 w-[200px]">
                                    <h5 className="text-sm font-medium uppercase xsm:text-base">{new Date(key.date_created).toLocaleDateString()}</h5>
                                </div>
                                <div className="p-2.5 text-center sm:block xl:p-5 w-[75px]">
                                    <h5 className="text-sm font-medium uppercase xsm:text-base pt-[5px]">
                                        <div className="group relative inline-block">
                                            <ModalConfirmDelete apiKeyId={key.id} onDelete={handleKeyDelete} />
                                            <div className="absolute bottom-full left-1/2 z-20 mb-3 -translate-x-1/2 whitespace-nowrap rounded bg-black px-4.5 py-1.5 text-sm font-medium text-white opacity-0 group-hover:opacity-100">
                                                <span className="absolute bottom-[-3px] left-1/2 -z-10 h-2 w-2 -translate-x-1/2 rotate-45 rounded-sm bg-black"></span>
                                                Delete API Key
                                            </div>
                                        </div>
                                    </h5>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>                
            </div>
            <div className="mt-[20px] rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
                    <h3 className="font-medium text-black dark:text-white">API Access</h3>
                </div>
                <div className="p-6.5">
                    <div className='text-[18px] font-bold pb-[10px]'>API Endpoint</div>
                    <div className='ml-[20px]'>
                        <ul className='list-disc'>
                            <li><span className='font-bold'>Endpoint:</span> https://api.decimalsports.com/embeddedplayer/</li>
                            <li><span className='font-bold'>Method:</span> GET</li>
                        </ul>
                    </div>
                    <div className='text-[18px] font-bold mt-[20px] pb-[10px]'>Required Headers</div>
                    <div className='ml-[20px]'>
                        <ul className='list-disc'>
                            <li><span className='font-bold'>`x-api-key`:</span> Replace &#123;your_api_key&#125; with the actual API key provided to you.</li>
                        </ul>
                    </div>
                    <div className='text-[18px] font-bold mt-[20px] pb-[10px]'>Example Using cUrl</div>
                    <div className='ml-[20px]'>
                        <pre className='whitespace-pre-wrap'>
                            <code className='whitespace-pre-wrap'>
                                curl -X GET https://api.decimalsports.com/embeddedplayer/ \ -H "x-api-key: your_api_key_here"
                            </code>
                        </pre>
                    </div>
                    <div className='text-[18px] font-bold mt-[20px] pb-[10px]'>Response Format</div>
                    <div className='ml-[20px]'>
                        <div>
                            Upon a successful GET request to the embeddedplayer endpoint, the API responds with a JSON object containing an array of fixtures (fixtures array). Each fixture object within the array includes the following fields:
                        </div>
                        <div className='ml-[20px]'>
                            <ul className='list-decimal'>
                                <li className='mt-[5px]'>
                                    <span className='font-bold'>'match_id':</span> (string):
                                    <ul className='list-disc'>
                                        <li>The unique identifier for the match.</li>
                                    </ul>
                                </li>
                                <li className='mt-[5px]'>
                                    <span className='font-bold'>'embed_id':</span> (string):
                                    <ul className='list-disc'>
                                        <li>The unique identifier for the embedded player associated with the fixture.</li>
                                    </ul>
                                </li>
                                <li className='mt-[5px]'>
                                    <span className='font-bold'>'name':</span> (string):
                                    <ul className='list-disc'>
                                        <li>The name or title of the match, including details such as team names, event qualifiers, and venue.</li>
                                    </ul>
                                </li>
                                <li className='mt-[5px]'>
                                    <span className='font-bold'>'status':</span> (string):
                                    <ul className='list-disc'>
                                        <li>The current status of the match (e.g., "Event Closed").</li>
                                    </ul>
                                </li>
                                <li className='mt-[5px]'>
                                    <span className='font-bold'>'match_start':</span> (string):
                                    <ul className='list-disc'>
                                        <li>The date and time when the match is scheduled to start, in ISO 8601 format (YYYY-MM-DDTHH:mm:ss.SSSZ).</li>
                                    </ul>
                                </li>
                                <li className='mt-[5px]'>
                                    <span className='font-bold'>'match_end':</span> (string):
                                    <ul className='list-disc'>
                                        <li>The date and time when the match ended, in ISO 8601 format (YYYY-MM-DDTHH:mm:ss.SSSZ). This field will be null for ongoing matches.</li>
                                    </ul>
                                </li>
                                <li className='mt-[5px]'>
                                    <span className='font-bold'>'competition':</span> (string):
                                    <ul className='list-disc'>
                                        <li>The name of the competition or tournament to which the match belongs (e.g., "ECS Romania").</li>
                                    </ul>
                                </li>
                                <li className='mt-[5px]'>
                                    <span className='font-bold'>'sport':</span> (integer):
                                    <ul className='list-disc'>
                                        <li>An identifier for the type of sport. (1=Kabaddi, 2=Padel, 4=Cricket).</li>
                                    </ul>
                                </li>
                                <li className='mt-[5px]'>
                                    <span className='font-bold'>'embeddedcode':</span> (string):
                                    <ul className='list-disc'>
                                        <li>The HTML embed code snippet that can be used to embed the player associated with the match on a webpage.</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>


                    </div>
                </div>
            </div>
        </DefaultLayout>
    )
};
