import React, { useEffect, useState, useRef } from "react";
import DefaultLayout from "../../layout/DefaultLayout";
import { Link } from 'react-router-dom';
import axios from "axios";
import ModalAddNewTournament from "../../components/Modals/ModalAddNewTournament";
import { useUser } from "../../UserContext";
import Cookies from 'js-cookie';
import { RiCornerDownRightFill } from "react-icons/ri"

export const AdminTournament = () => {
    const { user } = useUser();
    const st = Cookies.get('ds_session_token');
    const [session_token, setSession_token] = useState(st);
    const [tournaments, setTournaments] = useState([]);
    const [parents, setParents] = useState([]);
    const [loading, setLoading] = useState(true);


    async function fetchTournaments(session_token) {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/tournaments`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_API_KEY,
                },
            });
            if (response.status === 200) {
                let p=[];
                const data = await response.json();
                setTournaments(data.data);
                for (let i=0; i<data.data.length; i++) {
                    if (data.data[i].tournament_id===null) {
                        p.push(data.data[i]);
                    }
                }
                setParents(p);
                setLoading(false);
            } else {
                throw new Error('Failed to fetch data. Status: ' + response.status);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    async function addTournament(session_token, parent, newName, newId, newStartDate, newEndDate, selectedSport) {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/addTournament/${session_token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_API_KEY,
                },
                body: JSON.stringify({ name: newName, parent: parent, id: newId, startdate: newStartDate, enddate: newEndDate, sport: selectedSport })
            });
            if (response.status === 200) {
                fetchTournaments(session_token);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        fetchTournaments(session_token);
    }, [session_token]);
    
    const handleNewTournament = (parent, newName, newId, newStartDate, newEndDate, selectedSport) => {
        addTournament(session_token, parent, newName, newId, newStartDate, newEndDate, selectedSport);
    };

    return (
        <DefaultLayout>
            <h2 className="text-title-md2 font-semibold text-black dark:text-white mb-[20px]">Admin: Tournaments</h2>
            <div className="mt-[20px] rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
                    <h3 className="font-medium text-black dark:text-white">Streaming Tournaments</h3>
                </div>
                {loading ? (
                    <img src='/Spinner.gif' width='40px' height='40px' />
                ) : (
                    <div className='p-[20px]'>

                        <ModalAddNewTournament parents={parents} onUpdate={handleNewTournament} />
                        <div className='mt-[20px]'>

                            {tournaments.map((tour,idx) => (
                                <>
                                {tour.parent===null ? (
                                    <div className='pb-[20px]'>
                                        <div className='flex-grow'>
                                            <div className={`w-full flex pl-[10px] ${tour.date_from!==null ? ('bg-stroke') : ( null )}`}>
                                                <div className='w-[50%]'>{tour.tournament}</div>
                                                {tour.date_from!==null ? (
                                                    <div className='flex-grow'>{tour.date_from.substring(0,10)} - {tour.date_to.substring(0,10)}</div>
                                                ) : ( null )}
                                            </div>
                                        </div>
                                        {tournaments.map((t2,i2) => (
                                            <>
                                            {t2.parent===tour.id ? (
                                                <>
                                                <div className={`flex pl-[10px] ${t2.date_from!==null ? ('bg-stroke') : ( null )}`}>
                                                    <div className='pt-[3px] pr-[5px]'>
                                                        <RiCornerDownRightFill />
                                                    </div>
                                                    <div className='flex-grow'>
                                                        <div className="w-full flex">
                                                            <div className='w-[50%]'>{t2.tournament}</div>
                                                            {t2.date_from!==null ? (
                                                                <div className='flex-grow'>{t2.date_from.substring(0,10)} - {t2.date_to.substring(0,10)}</div>
                                                            ) : ( null )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {tournaments.map((t3,i3) => (
                                                    <>
                                                    {t3.parent===t2.id ? (
                                                    <div className={`flex pl-[30px] ${t3.date_from!==null ? ('bg-stroke') : ( null )}`}>
                                                        <div className='pt-[3px] pr-[5px]'>
                                                            <RiCornerDownRightFill />
                                                        </div>
                                                        <div className='flex-grow'>
                                                            <div className="w-full flex">
                                                                <div className='w-[50%]'>{t3.tournament}</div>
                                                                {t3.date_from!==null ? (
                                                                    <div className='flex-grow'>{t3.date_from.substring(0,10)} - {t3.date_to.substring(0,10)}</div>
                                                                ) : ( null )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    ) : ( null )}
                                                    </>
                                                ))}
                                                </>
                                        ) : ( null )}
                                        </>
                                        ))}
                                    </div>
                                ) : ( null )}
                                </>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </DefaultLayout>
    )
};
