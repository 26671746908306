import React, { useState, useEffect, useRef } from 'react';
import { FaRegEdit } from "react-icons/fa";

interface ModalEditApiNameProps {
  apiKeyId: string;
  currentName: string;
  onUpdate: (id: string, newName: string) => void;
}

const ModalEditApiName: React.FC<ModalEditApiNameProps> = ({ apiKeyId, currentName, onUpdate }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [newName, setNewName] = useState(currentName);

  const trigger = useRef<any>(null);
  const modal = useRef<any>(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!modal.current) return;
      if (
        !modalOpen ||
        modal.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setModalOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!modalOpen || keyCode !== 27) return;
      setModalOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const handleUpdate = () => {
    onUpdate(apiKeyId, newName);
    setModalOpen(false);
  };

  return (
    <div>
      <div ref={trigger} onClick={() => setModalOpen(!modalOpen)}>
        <FaRegEdit />
      </div>

      <div
        className={`fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/90 px-4 py-5 ${
          modalOpen ? 'block' : 'hidden'
        }`}
      >
        <div
          ref={modal}
          onFocus={() => setModalOpen(true)}
          onBlur={() => setModalOpen(false)}
          className="md:px-17.5 w-full max-w-142.5 rounded-lg bg-white px-8 py-12 text-center dark:bg-boxdark md:py-15"
        >
          <h3 className="pb-2 text-xl font-bold text-black dark:text-white sm:text-2xl">
            Edit API Key Name
          </h3>
          <span className="mx-auto mb-6 inline-block h-1 w-22.5 rounded bg-[#01949a]"></span>
          <p className="mb-10">
            <input
              type='text'
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              className='w-full border border-1 border-solid border-[#01949a] p-[5px]'
            />
          </p>
          <div className="-mx-3 flex flex-wrap gap-y-4">
            <div className="2xsm:w-1/2 w-full px-3">
              <button
                onClick={() => setModalOpen(false)}
                className="block w-full rounded border border-stroke bg-gray p-3 text-center font-medium text-black transition hover:border-meta-1 hover:bg-meta-1 hover:text-white dark:border-strokedark dark:bg-meta-4 dark:text-white dark:hover:border-meta-1 dark:hover:bg-meta-1"
              >
                Cancel
              </button>
            </div>
            <div className="2xsm:w-1/2 w-full px-3">
              <button
                onClick={handleUpdate}
                className="block w-full rounded border border-[#01949a] bg-[#01949a] p-3 text-center font-medium text-white transition hover:bg-opacity-90"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalEditApiName;
