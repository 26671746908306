import React, { useEffect, useState, useRef } from "react";
import DefaultLayout from "../../layout/DefaultLayout";
import { Link } from 'react-router-dom';
import axios from "axios";
import { useUser } from "../../UserContext";
import Cookies from 'js-cookie';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import ModalCalendarExpand from "../../components/Modals/ModalCalendarExpand"; 

export const Calendar = () => {
    const { user } = useUser();
    const st = Cookies.get('ds_session_token');
    const [session_token, setSession_token] = useState(st);
    const [thisMonth, setThisMonth] = useState();
    const [thisYear, setThisYear] = useState();
    const [calendarDays, setCalendarDays] = useState(["","",""]);
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1); 
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [firstDate, setFirstDate] = useState(null);
    const [lastDate, setLastDate] = useState(null);
    const [matches, setMatches] = useState([]);
    const [competitions, setCompetitions] = useState([]);
    const [expandedMatch, setExpandedMatch] = useState(null);

    const generateDistinctColors = (numColors) => {
        const colors = [];
        const step = 360 / numColors;
      
        for (let i = 0; i < numColors; i++) {
          const hue = i * step;
          const color = `hsl(${hue}, 70%, 50%)`;
          colors.push(color);
        }
      
        return colors;
    };

    const keyColors = [
        "#FF5733", "#33FF57", "#3357FF", "#F3FF33", "#33FFF3",
        "#FF33A8", "#A833FF", "#33FFCE", "#FFB533", "#5733FF",
        "#FF338A", "#33FFAB", "#338AFF", "#FF8333",
        "#33FF5A", "#8333FF", "#FF335C", "#33FF8A", "#FF5733",
        "#33F3FF", "#FF33DA", "#5733FF", "#FF3333", "#33FF66",
        "#33FFF3", "#FF33A8", "#A833FF", "#33FFCE", "#FFB533",
        "#5733FF", "#FF338A", "#33FFAB", "#338AFF", "#FFF033",
        "#FF8333", "#33FF5A", "#8333FF", "#FF335C", "#33FF8A",
        "#FF5733", "#33F3FF", "#FF33DA", "#5733FF", "#FF3333",
        "#33FF66", "#33FFF3", "#FF33A8", "#A833FF", "#33FFCE", "#FFF033"
      ];
      
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const getDaysInMonth = (month, year) => {
        return new Date(year, month, 0).getDate();
    };

    const getFirstDayOfWeek = (month, year) => {
        return new Date(year, month - 1, 1).getDay();
    };

    async function getMatches(fdate,ldate) {
        let baseUrl = process.env.REACT_APP_API_URL;
        let sessionToken = encodeURIComponent(session_token);
        let fromDate = encodeURIComponent(fdate);
        let toDate = encodeURIComponent(ldate);
        let url = `${baseUrl}/matchesbydaterange/${sessionToken}/${fromDate}/${toDate}`;
        const res = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_API_KEY
            },
        });
        const data = await res.json();

        let xcomp=competitions;
        for (let i=0; i<data.data.length; i++) {
            let y=data.data[i];
            if (!xcomp.includes(y.competition)) {
                xcomp.push(y.competition);
            }
        }
        setCompetitions(xcomp.sort());
        setMatches(data.data);
    }
    
    const loadCalendar = (nmonth, year) => {
        const daysInMonth = getDaysInMonth(nmonth, year);
        const firstDayOfWeek = getFirstDayOfWeek(nmonth, year);
      
        let Cal = [];
        let monthCount = 0;
      
        const adjustedFirstDay = (firstDayOfWeek + 6) % 7;
      
        const prevMonth = nmonth === 1 ? 12 : nmonth - 1;
        const prevYear = nmonth === 1 ? year - 1 : year;
        const daysInPrevMonth = getDaysInMonth(prevMonth, prevYear);
      
        for (let i = adjustedFirstDay; i > 0; i--) {
          let fdate = new Date(prevYear, prevMonth - 1, daysInPrevMonth - i + 1);
          Cal.push({ date: daysInPrevMonth - i + 1, shade: true, fulldate: formatDate(fdate) });
        }
      
        while (monthCount < daysInMonth) {
          monthCount++;
          let fdate = new Date(year, nmonth - 1, monthCount);
          Cal.push({ date: monthCount, shade: false, fulldate: formatDate(fdate) });
        }
      
        const totalDays = Cal.length;
        const remainingDays = 7 - (totalDays % 7);
      
        for (let i = 0; i < remainingDays && remainingDays < 7; i++) {
          let fdate = new Date(year, nmonth, i + 1);
          Cal.push({ date: i + 1, shade: true, fulldate: formatDate(fdate) });
        }
      
        setCalendarDays(Cal);
      
        const firstCalendarDate = new Date(year, nmonth - 2, Cal[0].date || 1);
        const lastCalendarDate = new Date(year, nmonth, Cal[Cal.length - 1].date || getDaysInMonth(nmonth, year));
        const firstDateString = formatDate(firstCalendarDate);
        const lastDateString = formatDate(lastCalendarDate);
        setFirstDate(firstDateString);
        setLastDate(lastDateString);
        getMatches(firstDateString, lastDateString);
    };

    const handleDecreaseMonth = (event) => {
        let newMonth = currentMonth - 1;
        let newYear = currentYear;
    
        if (newMonth < 1) {
          newMonth = 12;
          newYear -= 1;
        }
    
        setCurrentMonth(newMonth);
        setCurrentYear(newYear);
    };

    const handleIncreaseMonth = (event) => {
        let newMonth = currentMonth + 1;
        let newYear = currentYear;
    
        if (newMonth > 12) {
          newMonth = 1;
          newYear += 1;
        }
    
        setCurrentMonth(newMonth);
        setCurrentYear(newYear); 
    };

    useEffect(() => {
        const date = new Date(currentYear, currentMonth - 1); 
        const monthName = date.toLocaleString('default', { month: 'long' });
        setThisMonth(monthName);
        setThisYear(currentYear);
        loadCalendar(currentMonth, currentYear);
      }, [currentMonth, currentYear]);

    const handleExpandMatch = (matchId) => {
        setExpandedMatch(matchId);
    };
    const handleModalClose = () => {
        setExpandedMatch(null);
    };
    return (
        <DefaultLayout>
            <h2 className="text-title-md2 font-semibold text-black dark:text-white mb-[20px]">Stream Calendar</h2>

            <div className='flex h-[40px] mb-[10px]'>
                <div className='border border-1 border-solid border-[#01949a] text-[18px] pt-[10px] px-[10px] hover:bg-[#01949a] hover:text-[#fff] hover:bg-opacity-90 cursor-pointer'
                    onClick={handleDecreaseMonth} >
                    <FaAngleLeft />
                </div>
                <div className='bg-[#01949a] w-[150px] text-center'>
                    <h3 className='font-bold pt-[9px] px-[10px] text-white'>{thisMonth} {thisYear}</h3>
                </div>
                <div className='border border-1 border-solid border-[#01949a] text-[18px] pt-[10px] px-[10px] hover:bg-[#01949a] hover:text-[#fff] hover:bg-opacity-90 cursor-pointer'
                    onClick={handleIncreaseMonth} >
                    <FaAngleRight />
                </div>
            </div>


            <div className='w-full bg-[#01949a] text-white flex font-semibold'>
                <div className="w-1/7 border border-1 border-solid border-[#01949a] hidden lg:block text-center h-[60px] pt-[17px]"> Monday </div>
                <div className="w-1/7 border border-1 border-solid border-[#01949a] block lg:hidden text-center h-[60px] pt-[17px]"> Mon </div>

                <div className="w-1/7 border border-1 border-solid border-[#01949a] hidden lg:block text-center h-[60px] pt-[17px]"> Tuesday </div>
                <div className="w-1/7 border border-1 border-solid border-[#01949a] block lg:hidden text-center h-[60px] pt-[17px]"> Tue </div>

                <div className="w-1/7 border border-1 border-solid border-[#01949a] hidden lg:block text-center h-[60px] pt-[17px]"> Wednesday </div>
                <div className="w-1/7 border border-1 border-solid border-[#01949a] block lg:hidden text-center h-[60px] pt-[17px]"> Wed </div>

                <div className="w-1/7 border border-1 border-solid border-[#01949a] hidden lg:block text-center h-[60px] pt-[17px]"> Thursday </div>
                <div className="w-1/7 border border-1 border-solid border-[#01949a] block lg:hidden text-center h-[60px] pt-[17px]"> Thu </div>

                <div className="w-1/7 border border-1 border-solid border-[#01949a] hidden lg:block text-center h-[60px] pt-[17px]"> Friday </div>
                <div className="w-1/7 border border-1 border-solid border-[#01949a] block lg:hidden text-center h-[60px] pt-[17px]"> Fri </div>

                <div className="w-1/7 border border-1 border-solid border-[#01949a] hidden lg:block text-center h-[60px] pt-[17px]"> Saturday </div>
                <div className="w-1/7 border border-1 border-solid border-[#01949a] block lg:hidden text-center h-[60px] pt-[17px]"> Sat </div>

                <div className="w-1/7 border border-1 border-solid border-[#01949a] hidden lg:block text-center h-[60px] pt-[17px]"> Sunday </div>
                <div className="w-1/7 border border-1 border-solid border-[#01949a] block lg:hidden text-center h-[60px] pt-[17px]"> Sun </div>
            </div>


            <div className='flex flex-wrap'>
                {calendarDays.map((cal, idx) => (
                    <div className={`relative w-1/7 border border-1 border-solid border-[#01949a] h-[250px] w-fill ${cal.shade ? ('bg-[#01949a] bg-opacity-5') : ( 'bg-white' )}`}>
                        <div className='pt-[5px] pl-[10px] font-bold'>
                            {cal.date}
                        </div>

                        <div className='text-[12px] pt-[5px] px-[10px] h-[218px] overflow-auto'>
                            {matches.map((m,idy) => {
                              let compi=competitions.indexOf(m.competition);
                              const hex = keyColors[compi];
                              const r = parseInt(hex.slice(1, 3), 16);
                              const g = parseInt(hex.slice(3, 5), 16);
                              const b = parseInt(hex.slice(5, 7), 16);
                              const rgbaColor = `rgba(${r}, ${g}, ${b}, 0.5)`;
                              return (
                                <>
                                {m.match_start.substring(0,10)==cal.fulldate ? (
                                    <div className='flex cursor-pointer' onClick={() => handleExpandMatch(m.match_id)}>
                                        <div className='w-[45px]'>{m.match_start.substring(11,16)}</div>
                                        <div className='w-[12px] h-[12px] mt-[3px] mr-[3px]' style={{ backgroundColor: rgbaColor }}></div>
                                        <div className='w-[140px] text-left truncate'>
                                           {m.match_name.substring(0,m.match_name.indexOf(","))}
                                        </div>
                                    </div>
                                ) : ( null)}
                                </>
                              )
                            })}
                        </div>
                    </div>
                ))}
            </div>

            <div className='pt-[20px]'>
                <h2 className='font-semibold'>Key:</h2>
                <div className='flex flex-wrap'>
                    {competitions.map((comp, idx) => {
                        const hex = keyColors[idx];
                        const r = parseInt(hex.slice(1, 3), 16);
                        const g = parseInt(hex.slice(3, 5), 16);
                        const b = parseInt(hex.slice(5, 7), 16);
                        const rgbaColor = `rgba(${r}, ${g}, ${b}, 0.5)`;
                        return (
                            <div
                                key={idx}
                                className='text-black p-[5px] mr-[5px] mb-[5px] flex-none'
                                style={{ backgroundColor: rgbaColor }}
                            >
                                {comp}
                            </div>
                        )
                    })}
                </div>
            </div>

            {expandedMatch && (
                <ModalCalendarExpand
                    match_id={expandedMatch}
                    onClose={handleModalClose}
                />
            )}
        </DefaultLayout>
    )
};
